import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "The Flex Shorthand Doesn't Play Nice",
  "date": "2017-08-28",
  "promo": "flexbox",
  "description": "The flex shorthand changes flexbox defaults and has some major gotchas to watch out for",
  "color": "#64b800"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So many people jump straight to using the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand property right away and fall face first into the most common flexbox pitfall.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.all-the-things {
  flex: 1 0 300px;
}
`}</code></pre>
    <p>{`This one shorthand sets `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{`, `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{`, and `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` all in one. The first problem people have with this shorthand is not fully understanding all of those underlying properties being set. You're better off treating the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand as a convenience tool once you (and your team) have reached master proficiency with flexbox. Once you're there, just remember `}<strong parentName="p">{`grow, shrink, basis`}</strong>{` in that order.`}</p>
    <h2>{`Changing Defaults`}</h2>
    <p>{`Besides needing to understand all of flexbox, a big gotcha with this shorthand is that it sets `}<strong parentName="p">{`different defaults`}</strong>{`. This can bite you in a couple of ways. Say you're using `}<inlineCode parentName="p">{`flex`}</inlineCode>{` to set just the `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` for an item:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
  }\n
  .item {
    height: 200px;
    border: 10px solid;
    border-color: rgba(0,0,0,.3);
    /* setting just the basis also changed the flex-grow to 1 */
    flex: 30px;
  }\n
  .blue{ background: blue;}
  .purple{ background: rebeccapurple;}
`} html={`
  <div class="container">
    <div class="item blue"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`This set the `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` to `}<inlineCode parentName="p">{`30px`}</inlineCode>{` like we told it to, but it also changed the `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{` implicitly, making the items grow unexpectedly. If you're going to use this shorthand, remember that its default is `}<inlineCode parentName="p">{`flex-grow: 1`}</inlineCode>{` where the usual flexbox default is `}<inlineCode parentName="p">{`flex-grow: 0`}</inlineCode>{`.`}</p>
    <p>{`Another default that gets changed if you leave it off is `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`. Say you're using `}<inlineCode parentName="p">{`flex`}</inlineCode>{` to set just the `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{` properties, telling it to neither grow nor shrink:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
  }\n
  .item {
    height: 200px;
    width: 200px;
    border: 10px solid;
    border-color: rgba(0,0,0,.3);
    /* setting just the flex-grow and flex-shrink, made it also set flex-basis to 0 */
    flex: 0 0;
  }\n
  .red{ background: red; }
  .blue{ background: blue;}
  .green{ background: green;}
  .grey{ background: grey;}
  .purple{ background: rebeccapurple;}
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`Even though we didn't tell it to, this also changed the item's `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` from its usual default of `}<inlineCode parentName="p">{`auto`}</inlineCode>{` to a new value of `}<inlineCode parentName="p">{`0`}</inlineCode>{`. This new value made our items no longer take up any horizontal space (besides border size) despite have a width declared. To understand why see `}<a parentName="p" {...{
        "href": "/post/the-difference-between-width-and-flex-basis/"
      }}>{`the difference between width and flex-basis`}</a>{`.`}</p>
    <h2>{`Shorthand's shorthands`}</h2>
    <p>{`The final gotcha with the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand is that it accepts some magical keywords that set all three properties for you in a single word. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex: auto;
}
`}</code></pre>
    <p>{`is the same as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex: 1 1 auto;
}
`}</code></pre>
    <p>{`So if you're going to use this shorthand you need to memorize what the `}<inlineCode parentName="p">{`initial`}</inlineCode>{`, `}<inlineCode parentName="p">{`none`}</inlineCode>{`, `}<inlineCode parentName="p">{`auto`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<integer>`}</inlineCode>{` values map to, as well as what those properties actually do. It's super convenient to be able to do so much with one line of CSS, but only use this once you've really mastered flexbox.`}</p>
    <p>{`See more `}<a parentName="p" {...{
        "href": "/post/flexbox-pitfalls/"
      }}>{`Flexbox Pitfalls and How to Avoid Them`}</a></p>
    <h2>{`Challenge`}</h2>
    <p>{`Once you've gotten the hang of `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{`, `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{`, and `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` take your flexbox chops to the next level by trying out the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand. Commit its `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-flexbox-1/#flex-common"
      }}>{`keyword shorthands`}</a>{` to memory so you don't have to look them up when you come across them in your project. Flexbox Zombies `}<a parentName="p" {...{
        "href": "https://mastery.games/flexboxzombies/chapter/11/level/1"
      }}>{`chapter 11`}</a>{` will help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      